export const BG_PRIMARY = '#FEFEFE';
export const BG_SECONDARY = '#FFF2F2';
export const TEXT_PRIMARY = '#1A1B1D';
export const TEXT_SECONDARY = '#FEFEFE';

// digunain untuk main button color
// RSVP active progress stepper
// radio button when selected
export const BG_ALTERNATIVE = '#612235';

// ayat decoration
// RSVP inactive progress stepper
export const TEXT_DECORATION = '#612235';

export const BTN_NAVIGATION_COLOR = '#612235';

export const HEADING_STYLES = {
  fontFamily: 'heading',
  fontWeight: 'light',
  fontSize: '4xl',
};

export const HEADING_ALTERNATIVE_STYLES = {
  fontFamily: 'headingAlternative',
  letterSpacing: '2px',
  textTransform: 'uppercase',
  fontSize: 'md',
  textAlign: 'center',
};

export const HASHTAG_STYLE = {
  fontFamily: 'body',
  fontWeight: 'light',
  fontSize: '4xl',
  fontStyle: 'italic',
};

export const BUTTON_PROPS = {};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'light',
  size: 'sm',
  fontSize: 'xl',
  padding: '17px 40px',
  fontStyle: 'italic',
  type: 'button',
  bgColor: 'bgSecondary',
  fontFamily: 'body',
  borderRadius: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#1A1B1D',
};
